<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col xs="12" xl="8">
        <!-- notification area -->
        <div class="sticky-row">
          <v-alert
            v-show="error"
            transition="fade-transition"
            color="error"
            dense
          >
            <v-row align="center">
              <v-col class="grow d-flex align-center">
                <div class="mr-5">
                  <v-icon>mdi-alert-outline</v-icon>
                </div>

                <div>
                  {{ errorMessage }}
                </div>
              </v-col>
              <v-col class="shrink">
                <v-btn title="close" @click="error = false" icon>
                  <v-icon> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </div>

        <v-row>
          <v-col>
            <List type="loan" @error="handle_error" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import filt from "@/plugins/filters";
import List from "@/components/inquiry/List.vue";

export default {
  components: {
    List,
  },

  data: () => ({
    error: false,
    errorMessage: "",
  }),

  methods: {
    handle_error(e) {
      this.error = true;
      this.errorMessage = `Error ${e.response.status}: ${e.response.data.message}`;
    },
  },

  filters: {
    ...filt,
  },
};
</script>

<style scoped>
.sticky-row {
  position: sticky;
  top: 64px;
  z-index: 2;
}
</style>
